[v-cloak] {
    display: none;
}

.main-menu .active,
.main-menu li.nav-item:hover {
    background-color: #e7e7e7;
    font-weight: 600;
}

.swal2-content {
    font-size: 0.9375rem !important;
}

.instance-logo {
    height: 35px;
}